import { createSignal } from 'solid-js';

class SearchQuery {
    query: string;

    constructor(query: string) {
        this.query = query;
    }
}

const [searchQuery, setSearchQuery] = createSignal<SearchQuery>(new SearchQuery(''));

export function changeSearchQuery(query: string) {
    setSearchQuery({...searchQuery(), query: query})
}

export const getSearchQuery = searchQuery;
