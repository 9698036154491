import { createSignal } from "solid-js";

export const AnimatedDotsView = (props) => {
    const [count, setCount] = createSignal(0);

    const dotCount = props.count || 5
    const interval = props.interval || 500
    const elemClass = props.elemClass
    const label = props.label

    const dots = () => { return ".".repeat((count() % dotCount) + 1) };
    setInterval(() => setCount(count() + 1), interval);

    return <div class={elemClass}>
        {dots()}{label}{dots()}
    </div>
}
