import { getKeyData } from "../data/client-auth";
import { Show } from "solid-js/web";
import { MenuItem } from "../view-data/view-menu";
import { logOut } from "../logic/client-auth";

export const MenuView = (props) => {
    return <div class="menu">
        <div class="left-part">
            <div classList={{
                    "menu-item": true,
                    "selected": props.selected().item === MenuItem.Search
                }}
                onClick={(ev) => { 
                    props.setSelected(MenuItem.Search)
                }}
            >
                Home
            </div>
            <div classList={{
                    "menu-item": true,
                    "selected": props.selected().item === MenuItem.Likes
                }}
                onClick={(ev) => { 
                    props.setSelected(MenuItem.Likes)
                }}
            >
                Likes
            </div>
        </div>
        <div class="right-part">
            <div class="menu-item">
                <a href="https://x.com/SeewalleeSearch" target="_blank">X</a>
            </div>
            <div class="menu-item">
                <a href="https://reddit.com/r/seewalleesearch" target="_blank">Community</a>
            </div>
            <Show when={!getKeyData().validKey}>
                <div classList={
                    {
                        "menu-item": true,
                        "selected": props.selected().item === MenuItem.Login}
                    }
                    onClick={(ev) => { props.setSelected(MenuItem.Login) }}
                >
                    Log in
                </div>
            </Show>
            <Show when={getKeyData().validKey}>
                <div classList={
                    {
                        "menu-item": true,
                        "selected": props.selected().item === MenuItem.Login}
                    }
                    onClick={(ev) => { logOut() }}
                >
                    Log out
                </div>
            </Show>
        </div>
    </div>
}
