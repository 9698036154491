export function playAnimation(elemId: string, animClass: string, duration: number) {
    const element = document.getElementById(elemId)
    if (!element) {
        return
    }

    element.classList.add(animClass)
    setTimeout(() => {
        const element = document.getElementById(elemId)
        if (element) {
            element.classList.remove(animClass)
        }
    }, duration)
}

export function playScaleAnimation(elemId: string) {
    playAnimation(elemId, "animate-scale", 150)
}
