import { createSignal } from 'solid-js';
import { SearchType } from './search-settings';

class TweetEntry {
    tweetId: string
    tweetText: string
    tweetUrl: string
    tweetCreationTime: number
}

class PersonEntry {
    accountId: string
    accountUrl: string
    tweets: TweetEntry[]
    averageDist: number
    accountDisplayName: string
}

export class PeopleSearchResult {
    data: Array<PersonEntry>

    constructor(data: Array<PersonEntry>) {
        this.data = data
    }
}

class TweetEntryEx {
    accountId: string
    accountUrl: string
    tweetUrl: string
    content: string
    accountDisplayName: string
    creationTime: number
    tweetId: string
}

export class TweetSearchResult {
    data: Array<TweetEntryEx>

    constructor(data: Array<TweetEntryEx>) {
        this.data = data
    }    
}

export class SearchInFlight {
    query: string
    type: SearchType
    abort: AbortController

    constructor(query: string, type: SearchType, abort: AbortController) {
        this.query = query
        this.type = type
        this.abort = abort
    }
}

export type SearchFlowState = PeopleSearchResult | TweetSearchResult | SearchInFlight | Error | null;

export const [getSearchFlowState, changeSearchFlowState] = createSignal<SearchFlowState>(null);
