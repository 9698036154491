import { createSignal } from 'solid-js'

export enum SearchType {
    People = 'People',
    Ideas = 'Ideas'
}

export enum SearchItemCount {
    Hundred = 100,
    TwoHundreds = 200,
    ThreeHundreds = 300,
    FiveHundred = 500
}

export enum ShowSearchSettings {
    Yes = 'Yes',
    No = 'No'
}

export enum AugmentSearchQuery {
    Yes = 'Yes',
    No = 'No'
}

export class SearchSettings {
    show: ShowSearchSettings
    itemCount: SearchItemCount
    searchType: SearchType
    augmentSq: AugmentSearchQuery
    
    constructor(
        show: ShowSearchSettings,
        itemCount: SearchItemCount,
        type: SearchType,
        augmentSq: AugmentSearchQuery
    ) {
        this.show = show
        this.itemCount = itemCount
        this.searchType = type
        this.augmentSq = augmentSq
    }
}

export function makeSearchSettings() {
    const itemCount = parseInt(localStorage.getItem('searchItemCount') ?? '300')
    const itemCountEnum = (() => {
        if (itemCount == SearchItemCount.TwoHundreds) {
            return SearchItemCount.TwoHundreds
        }
        else if (itemCount == SearchItemCount.ThreeHundreds) {
            return SearchItemCount.ThreeHundreds
        }
        else if (itemCount == SearchItemCount.FiveHundred) {
            return SearchItemCount.FiveHundred
        }
        else {
            return SearchItemCount.Hundred
        }
    })()

    const showSettings = localStorage.getItem('showSearchSettings') ?? 'Yes'
    const showSettingsEnum = 
        showSettings == ShowSearchSettings.Yes ?
        ShowSearchSettings.Yes :
        ShowSearchSettings.No
    
    const lastSearchStr = localStorage.getItem('lastSearchType') ?? ''
    const lastSearch = (() => {
        if (lastSearchStr === SearchType.People) {
            return SearchType.People
        }
        else {
            return SearchType.Ideas
        }
    })()

    const augmentSq = localStorage.getItem('augmentSearchQuery') ?? 'No'
    const augmentSqEnum = 
        augmentSq == AugmentSearchQuery.Yes ?
        AugmentSearchQuery.Yes :
        AugmentSearchQuery.No

    return new SearchSettings(showSettingsEnum, itemCountEnum, lastSearch, augmentSqEnum)
}

export function toggleSearchSettingsVisibility() {
    if (getSearchSettings().show === ShowSearchSettings.Yes) {
        setSearchSettings({...getSearchSettings(), show: ShowSearchSettings.No})
    }
    else {
        setSearchSettings({...getSearchSettings(), show: ShowSearchSettings.Yes})
    }
    localStorage.setItem('showSearchSettings', getSearchSettings().show)
}

export function changeSearchItemCount(itemCount: SearchItemCount) {
    localStorage.setItem('searchItemCount', itemCount.toString())
    setSearchSettings({...getSearchSettings(), itemCount: itemCount})
}

export function changeSearchType(type: SearchType) {
    localStorage.setItem('lastSearchType', type)
    setSearchSettings({...getSearchSettings(), searchType: type})
}

export function changeAugmentSearchQuery(augmentSq: AugmentSearchQuery) {
    localStorage.setItem('augmentSearchQuery', augmentSq)
    setSearchSettings({...getSearchSettings(), augmentSq: augmentSq})    
}

const [searchSettings, setSearchSettings] = createSignal<SearchSettings>(makeSearchSettings());

export const getSearchSettings = searchSettings;
