import { changeSearchQuery, getSearchQuery } from "../data/search-query"
import { getSearchFlowState, TweetSearchResult, PeopleSearchResult, SearchInFlight } from "../data/search-result"
import { AugmentSearchQuery, changeAugmentSearchQuery, changeSearchType, SearchType } from '../data/search-settings'
import { searchFlow } from "../logic/client-search"
import { For, Match, Switch, Show } from "solid-js/web"
import { AnimatedDotsView } from "../utils/views"
import { getKeyData } from "../data/client-auth"
import { changeSearchItemCount, getSearchSettings, SearchItemCount, ShowSearchSettings, toggleSearchSettingsVisibility } from "../data/search-settings"
import { playAnimation, playScaleAnimation } from "../utils/animation"
import { LikeState } from "../data/client-likes"
import { createSignal, onMount } from "solid-js"
import { getLikes } from "../logic/client-likes"
import { HeartDiv, ThumbDiv } from "./view-hearts-thumbs"
import { encloseWithParagraph, prettyDate, removeQueryPrefix, replaceHyperLinks } from "../utils/text"
import { TweetList } from "./view-tweets"

const gearIcon = new URL('../static/gear.png', import.meta.url)

const SearchResultCount = (props) => {
    return <div
        id={props.elementId}
        classList={{highlight: getSearchSettings().itemCount === props.countEnum}}
        onClick={(ev) => {
            playScaleAnimation(props.elementId)
            changeSearchItemCount(props.countEnum) 
        }}
    >
        {props.countEnum}
    </div>
}

export const SearchQueryView = () => {
    return <>
        <div class="search-bar">
            <input
                value={getSearchQuery().query}
                onInput={(ev) => { changeSearchQuery(ev.target.value); }}
                onKeyPress={async (ev) => {
                    /** @ts-ignore */ // .value exists on Input target.
                    if (ev.key == "Enter" && ev.target.value.length > 0) {
                        await searchFlow(
                            getSearchQuery().query,
                            getSearchSettings().itemCount,
                            getSearchSettings().searchType,
                            getKeyData().validKey
                        )
                    }
                }}
                placeholder="Enter a sentence..."
            />
            <div class="complex-button">
                <div
                    id="explore-button"
                    class="highlight enlarged-padding"
                    onClick={async (ev) => {
                        playScaleAnimation("explore-button")
                        await searchFlow(
                            getSearchQuery().query,
                            getSearchSettings().itemCount,
                            getSearchSettings().searchType,
                            getKeyData().validKey
                        )
                    }}
                >
                    Explore
                </div>
            </div>
            <div class="complex-button">
                <div
                    id="people-search-button"
                    onClick={async (ev) => {
                        playScaleAnimation("people-search-button")
                        changeSearchType(SearchType.People)
                    }}
                    classList={{
                        "enlarged-padding": true,
                        "highlight": getSearchSettings().searchType === SearchType.People
                    }}
                >
                    People
                </div>
                <div
                    id="idea-search-button"
                    onClick={async (ev) => {
                        playScaleAnimation("idea-search-button")
                        changeSearchType(SearchType.Ideas)
                    }}
                    classList={{
                        "enlarged-padding": true,
                        "highlight": getSearchSettings().searchType === SearchType.Ideas
                    }}
                >
                    Views
                </div>
            </div>
            <div id="search-settings-button" class="search-settings-button" onClick={(ev) => { 
                toggleSearchSettingsVisibility()
                playScaleAnimation("search-settings-button")
                playAnimation("search-settings-box", "animate-scale-y", 225)
            }}>
                <img src={gearIcon} title="Search settings" />
            </div>
        </div>
        <Show when={getSearchSettings().show === ShowSearchSettings.Yes}>
            <div class="search-settings-box" id="search-settings-box">
                <div class="search-setting-container">
                    <div class="search-setting-label">Number of items per query</div>
                    <div class="complex-button">
                        <SearchResultCount elementId="search-item-count-100" countEnum={SearchItemCount.Hundred} />
                        <SearchResultCount elementId="search-item-count-200" countEnum={SearchItemCount.TwoHundreds} />
                        <SearchResultCount elementId="search-item-count-300" countEnum={SearchItemCount.ThreeHundreds} />
                        <SearchResultCount elementId="search-item-count-500" countEnum={SearchItemCount.FiveHundred} />
                    </div>
                </div>
                <div class="search-setting-container">
                    <div class="search-setting-label">Add search instruction to embedding</div>
                    <div class="complex-button">
                        <div
                            id="augment-sq-yes"
                            classList={{highlight: getSearchSettings().augmentSq === AugmentSearchQuery.Yes}}
                            onClick={(ev) => {
                                playScaleAnimation("augment-sq-yes")
                                changeAugmentSearchQuery(AugmentSearchQuery.Yes) 
                            }}
                        >
                            Yes
                        </div>
                        <div
                            id="augment-sq-no"
                            classList={{highlight: getSearchSettings().augmentSq === AugmentSearchQuery.No}}
                            onClick={(ev) => {
                                playScaleAnimation("augment-sq-no")
                                changeAugmentSearchQuery(AugmentSearchQuery.No)
                            }}
                        >
                            No
                        </div>
                    </div>
                </div>
            </div>
        </Show>
    </>
}

export const PersonEntryView = (props) => {
    return <div class="search-result-entry">
        <div class="account-name">
            <div class="person-link">
                <a href={props.person.accountUrl} target="_blank">
                    {props.index() + 1})&nbsp;
                    {
                        props.person.accountDisplayName.length > 0 ?
                            props.person.accountDisplayName :
                            props.person.accountUrl.replace("https://", "")
                    }
                </a>
            </div>
            <HeartDiv like={props.accounts[props.person.accountId]} account={props.person.accountId} />
        </div>
        <For each={props.person.tweets}>{(tweet) =>
            <>
                <div class="post-content" innerHTML={
                    encloseWithParagraph(replaceHyperLinks(tweet.tweetText))
                }>
                </div>
                <div class="post-time-row post-border">
                    <div class="post-time">
                        <a href={tweet.tweetUrl} target="_blank">
                            {prettyDate(new Date(tweet.tweetCreationTime))}
                        </a>
                    </div>
                    <ThumbDiv like={props.tweets[tweet.tweetId]} tweet={tweet.tweetId} />
                </div>
            </>
        }</For>
    </div>
}

export const PeopleList = (props) => {
    const accounts = {}
    const tweets = {}
    for (const account of props.data.data) {
        accounts[account.accountId] = createSignal<LikeState>(LikeState.Unknown)
        for (const tweet of account.tweets) {
            tweets[tweet.tweetId] = createSignal<LikeState>(LikeState.Unknown)
        }
    }
    onMount(() => {
        getLikes(accounts, tweets)
    })
    return <div class="entry-list">
        <For each={props.data.data}>{(person, index) =>
            <PersonEntryView person={person} index={index} accounts={accounts} tweets={tweets} />
        }</For>
    </div>
}

export const SearchResultView = () => {
    return <div class="search-result-box">
        <Switch>
            <Match when={getSearchFlowState() instanceof SearchInFlight}>
                <AnimatedDotsView
                    label={
                        "Searching " + 
                        (
                            (getSearchFlowState() as SearchInFlight).type === SearchType.Ideas ?
                            "ideas" : "people"
                        ) + 
                        " associated with «" +
                        removeQueryPrefix((getSearchFlowState() as SearchInFlight).query) + "»"
                    }
                    elemClass="search-in-progress" 
                />
                <div class="cup-of-tea">It may take a while... Perhaps, a cup of tea?</div>
            </Match>
            <Match when={getSearchFlowState() instanceof Error}>
                <div class="error">
                    <b>Search failed!</b>
                    <div innerHTML={(getSearchFlowState() as Error).message}></div>
                </div>
            </Match>
            <Match when={getSearchFlowState() instanceof PeopleSearchResult}>
                <PeopleList data={(getSearchFlowState() as PeopleSearchResult)} />
            </Match>
            <Match when={getSearchFlowState() instanceof TweetSearchResult}>
                <TweetList data={(getSearchFlowState() as TweetSearchResult).data} />
            </Match>
        </Switch>
    </div>
}
