import { createSignal } from 'solid-js'

export class KeyVerificationInFlight {}

export type KeyVerificationFlowState = KeyVerificationInFlight | Error | null;

export const [getKeyVerificationFlowState, changeKeyVerificationFlowState] = 
    createSignal<KeyVerificationFlowState>(null)

class KeyData {
    currentKey: string
    validKey?: string

    constructor(currentKey: string, validKey?: string) {
        this.currentKey = currentKey;
        this.validKey = validKey;
    }
}

function makeKeyData() {
    return new KeyData(localStorage.getItem('currentKey') ?? '', localStorage.getItem('validKey'));
}

export function changeCurrentKey(key: string) {
    setKeyData({...getKeyData(), currentKey: key});
    localStorage.setItem('currentKey', key);
}

export function apiKeyValidated(apiKey: string) {
    setKeyData({...getKeyData(), validKey: apiKey})
    localStorage.setItem('validKey', apiKey)
}

export function dropValidKey() {
    setKeyData({...getKeyData(), validKey: null})
    localStorage.removeItem('validKey')
}

const [keyData, setKeyData] = createSignal<KeyData>(makeKeyData());

export const getKeyData = keyData;
