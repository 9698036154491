export function findInvalidSymbolInToken(apiKey: string): string | null {
    for (const character of apiKey) {
        if (character >= 'A' && character <= 'Z')
            continue
        if (character >= 'a' && character <= 'z')
            continue
        if (character >= '0' && character <= '9')
            continue

        return character === ' ' ? 'space' : character;
    }
    return null;
}
