import { IFlags } from "./data/client-iflags";

// Make a copy of this file and call it flags.tsx to start working on the frontend.
// Change fields of "flags" constant below to influence frontend's behavior.

const flags: IFlags = {
    search: {
        // Search endpoint prefix.
        urlPrefix: '/api',
        // Display average distance from person's tweets to search query string.
        showAverageDistance: false
    }
}

export function getFlags(): IFlags {
    return flags;
}
