export async function fetchCatchingErrors(
    request: Request
): Promise<string | Error> {
    try {
        const response = await fetch(request, {signal: AbortSignal.timeout(60000)})
        if (!response.ok) {
            return new Error(`Bad response, code ${response.status}`)
        }
        else {
            return await response.text()
        }
    } catch (e) {
        if (e.name === 'TimeoutError') {
            return new Error('${fetchQueryName} request timeout');
        }
        else {
            return e
        }
    }
}
