import { apiKeyValidated, KeyVerificationInFlight, changeKeyVerificationFlowState, dropValidKey, getKeyVerificationFlowState } from "../data/client-auth";
import { changeSearchFlowState, getSearchFlowState } from "../data/search-result";
import { getFlags } from "../flags";
import { MenuItem, changeLastSelectedMenuItem } from "../view-data/view-menu";

async function verifyKey(apiKey: string): Promise<boolean | Error> {
    const url = `${getFlags().search.urlPrefix}/free/check-key?key=${apiKey}`
    const request = new Request(url, {method: 'GET'})
    try {
        const response = await fetch(request)
        if (!response.ok) {
            return new Error(`Bad server response, code ${response.status}`)
        } else {
            const result = await response.json()
            return result.result
        }
    } catch (e) {
        return e
    }
}

export function logOut() {
    changeLastSelectedMenuItem(MenuItem.Search)
    dropValidKey()
}

export async function apiKeyVerificationFlow(apiKey: string) {
    if (getKeyVerificationFlowState() instanceof KeyVerificationInFlight) {
        return
    }

    if (apiKey.length === 0) {
        return
    }

    changeKeyVerificationFlowState(new KeyVerificationInFlight())

    const result = await verifyKey(apiKey)
    if (result instanceof Error) {
        changeKeyVerificationFlowState(result)
        return
    }

    if (!result) {
        changeKeyVerificationFlowState(new Error('API key is invalid'))
        return
    }

    if (result) {
        changeKeyVerificationFlowState(null)
        apiKeyValidated(apiKey)
        changeLastSelectedMenuItem(MenuItem.Search)
        if (getSearchFlowState() instanceof Error) {
            changeSearchFlowState(null)
        }
    }
}
