const _regexpForReplacingHyperlinks = new RegExp(/<a([^>]* )(href|src)=\"(.*?)\"(.*?)>(.*?)<\/a>/gi);

export function replaceHyperLinks(html: string): string {
    return html.replace(_regexpForReplacingHyperlinks, '<span class="seewallee-href">$5</span>');
}

export function encloseWithParagraph(html: string): string {
    const trimmed = html.trim()
    if (trimmed.indexOf('<p>') !== 0) {
        return ['<p>', trimmed, '</p>'].join('')
    } else {
        return trimmed
    }
}

export function prettyDate(date: Date) {
    return ("0" + date.getDate()).slice(-2) + "-" +
        ("0"+(date.getMonth()+1)).slice(-2) + "-" +
        date.getFullYear()
}

export function removeQueryPrefix(value: string) {
    if (value.indexOf("search_query: ") === 0) {
        return value.slice("search_query: ".length)
    }
    else {
        return value
    }
}
