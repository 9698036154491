import { render } from "solid-js/web";
import { MenuView } from "./views/view-menu";
import { VerifyKeyView } from "./views/view-auth";
import { SearchQueryView, SearchResultView } from "./views/view-search"
import { Show } from "solid-js/web";
import { MenuItem, getSelectedMenuItem, changeLastSelectedMenuItem } from "./view-data/view-menu";
import { LikesView } from "./views/view-likes";

const App = () => {
    return <>
        <div class="header">
            <div class="title">Seewallee Search (&alpha;)</div>
            <div class="subtitle">Explore the world of people's ingenuity.</div>
            <div class="subtitle">See connections. Encounter surprises.</div>
            <div class="subtitle"> Find inspiration. Find new friends.</div>
        </div>
        <div class="main">
            <MenuView selected={getSelectedMenuItem} setSelected={changeLastSelectedMenuItem} />
            <Show when={getSelectedMenuItem().item === MenuItem.Search
            }>
                <SearchQueryView/>
                <SearchResultView/>
            </Show>
            <Show when={getSelectedMenuItem().item === MenuItem.Login}>
                <VerifyKeyView/>
            </Show>
            <Show when={getSelectedMenuItem().item === MenuItem.Likes}>
                <LikesView/>
            </Show>
        </div>
        <div class="footer">
            <div class="title">Seewallee</div>
            <div class="subtitle">Vision for everyone</div>
            <div class="icon-attribution">
                <a href="https://www.flaticon.com/free-icons/gear" title="gear icons">Gear icons created by Freepik - Flaticon</a>
                <br/>
                <a href="https://www.flaticon.com/free-icons/heart" title="heart icons">Heart icons created by Chanut - Flaticon</a>
                <br/>
                <a href="https://www.flaticon.com/free-icons/thumb" title="thumb icons">Thumb icons created by Anggara - Flaticon</a>
            </div>
        </div>
    </>
};

render(() => <App/>, document.body);
