export enum LikeableObjType {
    Account = 'account',
    Tweet = 'tweet'
}

export type LikeableObjId = string

export interface LikeableObj {
    id: LikeableObjId
    type: LikeableObjType
}
