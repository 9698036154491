import { fetchCatchingErrors } from "../utils/http"
import { getKeyData } from "../data/client-auth"
import { LikesDict, LikeState } from "../data/client-likes"
import { getFlags } from "../flags"
import { LikeableObj, LikeableObjType } from "../../common/likes"
import { Signal } from "solid-js"

export async function toggleLike(obj: LikeableObj, signal: Signal<LikeState>) {
    if (!getKeyData().validKey) {
        return
    }

    const currentState = signal[0]()

    if (currentState !== LikeState.Liked && currentState !== LikeState.NotLiked) {
        return
    }

    signal[1](currentState === LikeState.Liked ? LikeState.Unliking : LikeState.Liking)

    const endpointAction = currentState === LikeState.Liked ? 'unlike' : 'like'
    const endpointObj = obj.type === LikeableObjType.Account ? 'account' : 'tweet'

    const result = await fetchCatchingErrors(new Request(
        `${getFlags().search.urlPrefix}/locked/likes/${endpointAction}-${endpointObj}?id=${obj.id}`, {
            method: 'GET', headers: {'Authorization': `Bearer ${getKeyData().validKey}`}
        }
    ))
    if (result instanceof Error) {
        return
    }
    const jsonResult = JSON.parse(result)
    if ('error' in jsonResult) {
        return
    }
    signal[1](currentState === LikeState.Liked ? LikeState.NotLiked : LikeState.Liked)
}

export async function getLikes(accounts: LikesDict, tweets: LikesDict) {
    if (!getKeyData().validKey) {
        return
    }

    const flatAccounts = []
    for (const accountId in accounts) {
        flatAccounts.push(accountId)
    }

    const flatTweets = []
    for (const tweetId in tweets) {
        flatTweets.push(tweetId)
    }

    const accountsPromise = fetchCatchingErrors(new Request(
        `${getFlags().search.urlPrefix}/locked/likes/of-accounts`, {
            method: 'POST',
            body: JSON.stringify({ids: flatAccounts}),
            headers: {'Authorization': `Bearer ${getKeyData().validKey}`}
        }
    ))

    const tweetsPromise = fetchCatchingErrors(new Request(
        `${getFlags().search.urlPrefix}/locked/likes/of-tweets`, {
            method: 'POST',
            body: JSON.stringify({ids: flatTweets}),
            headers: {'Authorization': `Bearer ${getKeyData().validKey}`}
        }
    ))

    accountsPromise.then((result) => {
        if (result instanceof Error) {
            return
        }
        const likeData = JSON.parse(result)['result']
        for (const accountId in accounts) {
            const [_, setter] = accounts[accountId]
            setter(likeData[accountId] ? LikeState.Liked : LikeState.NotLiked)
        }
    })

    tweetsPromise.then((result) => {
        if (result instanceof Error) {
            return
        }
        const likeData = JSON.parse(result)['result']
        for (const tweetId in tweets) {
            const [_, setter] = tweets[tweetId]
            setter(likeData[tweetId] ? LikeState.Liked : LikeState.NotLiked)
        }
    })
}
