import { changeSearchFlowState, getSearchFlowState, SearchInFlight, SearchFlowState, PeopleSearchResult, TweetSearchResult } from "../data/search-result"
import { AugmentSearchQuery, getSearchSettings, SearchType } from '../data/search-settings'
import { getFlags } from "../flags"
import { findInvalidSymbolInToken } from "../../common/auth"

async function launchSearch(
    query: string,
    itemCount: number,
    apiKey: string,
    searchType: SearchType,
    abort: AbortController
): Promise<SearchFlowState> 
{
    const typeStr = searchType === SearchType.Ideas ? 'ideas' : 'people'
    const url = `${getFlags().search.urlPrefix}/locked/search/${typeStr}?q=${query}&c=${itemCount}`
    const request = new Request(url, {method: 'GET', headers: {'Authorization': `Bearer ${apiKey}`}})
    try {
        const response = await fetch(request, {signal: abort.signal})
        if (!response.ok) {
            if (response.status == 401) {
                return new Error('Authorization failed. Please ensure that you use correct API key.');
            } else {
                return new Error(`Bad server response, code ${response.status}`)
            }
        } else {
            if (searchType === SearchType.People) {
                return new PeopleSearchResult((await response.json())['result'])
            }
            else {
                return new TweetSearchResult((await response.json())['result'])
            }
        }
    } catch (e) {
        return e
    }
}

export async function searchFlow(query: string, itemCount: number, searchType: SearchType, apiKey: string) {
    if (query.length == 0) {
        return
    }

    if (apiKey === null) {
        changeSearchFlowState(new Error('Your API key is not validated. Go to "Log in" section and validate it.'))
        return
    }

    if (apiKey.length == 0) {
        changeSearchFlowState(new Error('Your API key is empty.'))
        return
    }

    if (getSearchSettings().augmentSq == AugmentSearchQuery.Yes) {
        query = 'search_query: ' + query
    }

    // Interrupt currently running search.
    const currentFlow = getSearchFlowState()
    if (currentFlow instanceof SearchInFlight && (currentFlow.query != query || currentFlow.type != searchType)) {
        currentFlow.abort.abort()
        changeSearchFlowState(null)
    }

    const invalidSymbol = findInvalidSymbolInToken(apiKey)

    if (invalidSymbol !== null) {
        const errorMsg = [
            `Your API key is invalid, because it contains invalid symbol: `,
            `<span style="color: black">${invalidSymbol}</span>`
        ].join("")
        changeSearchFlowState(new Error(errorMsg))
        return
    }

    const searchInFlight = new SearchInFlight(query, searchType, new AbortController())
    changeSearchFlowState(searchInFlight)
    const result = await launchSearch(query, itemCount, apiKey, searchType, searchInFlight.abort)
    if (result instanceof Error && result.name == 'AbortError') {
        console.log(`Query '${query}' has been aborted`)
        return
    }

    changeSearchFlowState(result)
}
