import { createSignal, Signal } from "solid-js";
import { LikeableObjId } from "../../common/likes";

export enum LikeState {
    Unknown,
    NotLiked,
    Liked,
    Liking,
    Unliking
}

export interface LikesDict {
    [Key: LikeableObjId]: Signal<LikeState>
}

export interface PageLikes {
    accounts: LikesDict,
    tweets: LikesDict
}

export enum LikesPageChapter {
    Tweets = 'Tweets',
    Accounts = 'Accounts'
}

export class LikesPageData {
    chapter: LikesPageChapter

    constructor(chapter: LikesPageChapter) {
        this.chapter = chapter
    }
}

export function makeLikesPageData() {
    const chapterStr = localStorage.getItem('likesPageChapter') ?? LikesPageChapter.Accounts
    const chapter = (() => {
        if (chapterStr == LikesPageChapter.Tweets) {
            return LikesPageChapter.Tweets
        }
        else {
            return LikesPageChapter.Accounts
        }
    })()

    return new LikesPageData(chapter)
}

const [likesPageData, setLikesPageData] = createSignal<LikesPageData>(makeLikesPageData())

export function changeLikesPageChapter(chapter: LikesPageChapter) {
    setLikesPageData(new LikesPageData(chapter))
    localStorage.setItem('likesPageChapter', chapter)
}

export const getLikesPageData = likesPageData
