import { Show } from "solid-js/web"
import { getKeyData } from "../data/client-auth"
import { LikeState } from "../data/client-likes"
import { toggleLike } from "../logic/client-likes"
import { LikeableObjType } from "../../common/likes"

const heartIcon = new URL('../static/heart.png', import.meta.url)
const heartFullIcon = new URL('../static/heart-full.png', import.meta.url)
const heartQuestionIcon = new URL('../static/heart-question.png', import.meta.url)
const thumbIcon = new URL('../static/like.png', import.meta.url)
const thumbFullIcon = new URL('../static/like-full.png', import.meta.url)
const thumbQuestionIcon = new URL('../static/like-question.png', import.meta.url)

export const HeartDiv = (props) => {
    return <div
            classList={{
                "heart": true,
                "animate-like-scale":
                    props.like[0]() !== LikeState.Liked && props.like[0]() !== LikeState.NotLiked
            }}
            onClick={async () => {
                await toggleLike({id: props.account, type: LikeableObjType.Account}, props.like)
            }}
        >
        <Show when={getKeyData().validKey}>
            <Show when={props.like[0]() === LikeState.Liked}>
                <img src={heartFullIcon} title="Liked" />
            </Show>
            <Show when={props.like[0]() === LikeState.NotLiked}>
                <img src={heartIcon} title="Not liked yet" />
            </Show>
            <Show when={props.like[0]() !== LikeState.Liked && props.like[0]() !== LikeState.NotLiked}>
                <img src={heartQuestionIcon} title="Loading..." />
            </Show>
        </Show>
    </div>
}

export const ThumbDiv = (props) => {
    return <div
            classList={{
                "thumb": true,
                "animate-like-scale":
                    props.like[0]() !== LikeState.Liked && props.like[0]() !== LikeState.NotLiked
            }}
            onClick={async () => {
                await toggleLike({id: props.tweet, type: LikeableObjType.Tweet}, props.like)
            }}
        >
        <Show when={getKeyData().validKey}>
            <Show when={props.like[0]() === LikeState.Liked}>
                <img src={thumbFullIcon} title="Liked" />
            </Show>
            <Show when={props.like[0]() === LikeState.NotLiked}>
                <img src={thumbIcon} title="Not liked yet" />
            </Show>
            <Show when={props.like[0]() !== LikeState.Liked && props.like[0]() !== LikeState.NotLiked}>
                <img src={thumbQuestionIcon} title="Loading..." />
            </Show>
            </Show>
    </div>
}
