import { changeLikesPageChapter, getLikesPageData, LikesPageChapter } from "../data/client-likes"
import { playScaleAnimation } from "../utils/animation"

export const LikesView = () => {
    return <div class="chapter-box likes-heading">
        <div class="complex-button">
            <div
                id="likes-page-chapter-accounts"
                classList={{"enlarged-padding": true, highlight: getLikesPageData().chapter == LikesPageChapter.Accounts}}
                onClick={(ev) => {
                    playScaleAnimation("likes-page-chapter-accounts")
                    changeLikesPageChapter(LikesPageChapter.Accounts) 
                }}
            >
                People
            </div>
            <div
                id="likes-page-chapter-tweets"
                classList={{"enlarged-padding": true, highlight: getLikesPageData().chapter == LikesPageChapter.Tweets}}
                onClick={(ev) => {
                    playScaleAnimation("likes-page-chapter-tweets")
                    changeLikesPageChapter(LikesPageChapter.Tweets) 
                }}
            >
                Views
            </div>
        </div>
    </div>
}
