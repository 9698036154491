import { KeyVerificationInFlight, changeCurrentKey, getKeyVerificationFlowState, getKeyData } from "../data/client-auth";
import { Match, Switch, Show } from "solid-js/web";
import { apiKeyVerificationFlow } from "../logic/client-auth";
import { AnimatedDotsView } from "../utils/views";

export const VerifyKeyView = () => {
    return <div>
        <div class="auth-bar">
            <Show when={!getKeyData().validKey}>
                <input
                    value={getKeyData().currentKey}
                    onInput={(ev) => { changeCurrentKey(ev.target.value); }}
                    placeholder="Enter API key..."
                />
                <div class="complex-button">
                    <div
                        class="highlight enlarged-padding"
                        onClick={async (ev) => { await apiKeyVerificationFlow( getKeyData().currentKey ) }}
                    >
                        Verify
                    </div>
                </div>
            </Show>
        </div>
        <div class="auth-result">
            <Switch>
                <Match when={getKeyVerificationFlowState() instanceof KeyVerificationInFlight}>
                    <AnimatedDotsView label="Verifying" elemClass="key-verification-in-progress" />
                </Match>
                <Match when={getKeyVerificationFlowState() instanceof Error}>
                    <div class="error">
                        <b>Verification failed!</b>
                        <div innerHTML={(getKeyVerificationFlowState() as Error).message} />
                    </div>
                </Match>
            </Switch>
        </div>
    </div>
}
