import { For } from "solid-js/web"
import { LikeState } from "../data/client-likes"
import { createSignal, onMount } from "solid-js"
import { getLikes } from "../logic/client-likes"
import { HeartDiv, ThumbDiv } from "./view-hearts-thumbs"
import { encloseWithParagraph, prettyDate, replaceHyperLinks } from "../utils/text"

const TweetView = (props) => {
    return <div class="search-result-entry">
        <div class="account-name">
            <div class="person-link">
                <a href={props.idea.accountUrl} target="_blank">
                    {props.index() + 1})&nbsp;
                    {
                        props.idea.accountDisplayName.length > 0 ?
                            props.idea.accountDisplayName :
                            props.idea.accountUrl.replace("https://", "")
                    }
                </a>
            </div>
            <HeartDiv like={props.accounts[props.idea.accountId]} account={props.idea.accountId} />
        </div>
        <div class="post-content" innerHTML={
            encloseWithParagraph(replaceHyperLinks(props.idea.content))
        }>
        </div>
        <div class="post-time-row">
            <div class="post-time">
                <a href={props.idea.tweetUrl} target="_blank">
                    {prettyDate(new Date(props.idea.creationTime))}
                </a>
            </div>
            <ThumbDiv like={props.tweets[props.idea.tweetId]} tweet={props.idea.tweetId} />
        </div>
    </div>
}

export const TweetList = (props) => {
    const accounts = {}
    const tweets = {}
    for (const idea of props.data) {
        accounts[idea.accountId] = createSignal<LikeState>(LikeState.Unknown)
        tweets[idea.tweetId] = createSignal<LikeState>(LikeState.Unknown)
    }
    onMount(() => {
        getLikes(accounts, tweets)
    })
    return <div class="entry-list">
        <For each={props.data}>{(idea, index) =>
            <TweetView idea={idea} index={index} accounts={accounts} tweets={tweets} />
        }</For>
    </div>
}
